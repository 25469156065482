import * as tslib_1 from "tslib";
import { Events, NavController, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '../user/user.service';
import * as firebase from 'firebase';
import { map, first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from '../config/config.service';
import { CartService } from '../cart/cart.service';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { LabelService } from '../label/label.service';
import { AngularFireStorage } from '@angular/fire/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic/storage";
import * as i3 from "@angular/fire/firestore";
import * as i4 from "../user/user.service";
import * as i5 from "@angular/router";
import * as i6 from "../config/config.service";
import * as i7 from "../loggly-logger/loggly-logger.service";
import * as i8 from "../label/label.service";
import * as i9 from "@angular/fire/storage";
import * as i10 from "../cart/cart.service";
import * as i11 from "@angular/common";
var OrderService = /** @class */ (function () {
    function OrderService(events, storage, afs, userService, navController, router, modalController, loadingController, alertController, configService, logglyService, labelService, angularFireStorage, cartService, fbStorage, activatedRoute, document) {
        this.events = events;
        this.storage = storage;
        this.afs = afs;
        this.userService = userService;
        this.navController = navController;
        this.router = router;
        this.modalController = modalController;
        this.loadingController = loadingController;
        this.alertController = alertController;
        this.configService = configService;
        this.logglyService = logglyService;
        this.labelService = labelService;
        this.angularFireStorage = angularFireStorage;
        this.cartService = cartService;
        this.fbStorage = fbStorage;
        this.activatedRoute = activatedRoute;
        this.document = document;
    }
    OrderService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('order:payWithRazorPay', function (order, razorpayId, method) {
            _this.payWithRazorPay(order, razorpayId, method);
        });
        this.events.subscribe('order:payWithPaytm', function (order) {
            _this.payWithPaytm(order);
        });
        this.events.subscribe('order:sendPaymentRequest', function (orderId, userId) {
            _this.sendPaymentRequest(orderId, userId);
        });
        this.events.subscribe('order:payWithCash', function (order) {
            _this.payWithCash(order);
        });
        this.events.subscribe('order:updatePaymentComplete', function (orderId) {
            _this.updatePaymentComplete(orderId);
        });
        this.events.subscribe('order:completePaymentWithWallet', function (order) {
            _this.completePaymentWithWallet(order);
        });
        //auto confirm...
        this.events.subscribe('order:ac_payWithRazorPay', function (order, razorpayId, method) {
            _this.ac_payWithRazorPay(order, razorpayId, method);
        });
        this.events.subscribe('order:ac_payWithPaytm', function (order) {
            _this.ac_payWithPaytm(order);
        });
        this.events.subscribe('order:ac_payWithCash', function (order) {
            _this.ac_payWithCash(order);
        });
        this.events.subscribe('order:ac_completePaymentWithWallet', function (order) {
            _this.ac_completePaymentWithWallet(order);
        });
        this.events.subscribe('order:ac_paytmWebInitiateTxn', function (order) {
            _this.ac_paytmWebInitiateTxn(order);
        });
        this.events.subscribe('order:ac_completeUPIManualPayment', function (order, paymentImg) {
            _this.ac_completeUPIManualPayment(order, paymentImg);
        });
        this.events.subscribe('order:ac_completeCustomOptionPayment', function (order, response) {
            _this.ac_completeCustomOptionPayment(order, response);
        });
        this.events.subscribe('order:placeOrder', function (products, listOfCommentImages, address, paymentObj) {
            _this.placeOrder(products, listOfCommentImages, address, paymentObj);
        });
        this.events.subscribe('order:autoConfirmPlaceOrder', function (products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon) {
            _this.autoConfirmPlaceOrder(products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon);
        });
    };
    OrderService.prototype.payWithRazorPay = function (order, razorpayId, method) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payableAmnt, createOrderInRazorpay, createOrderRes, razorpayOrderId, storeInfo, options_1, razorpay, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
                        return [4 /*yield*/, createOrderInRazorpay({ amount: Math.round(payableAmnt * 100), orderDocId: order.id })];
                    case 2:
                        createOrderRes = _a.sent();
                        razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
                        console.log('razorpayOrderId', razorpayOrderId);
                        if (!razorpayOrderId) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.storage.get('storeInfo')];
                    case 3:
                        storeInfo = _a.sent();
                        options_1 = {
                            order_id: razorpayOrderId,
                            description: this.configService.environment.razorpay.description,
                            currency: this.configService.environment.razorpay.currency,
                            key: razorpayId,
                            amount: Math.round(payableAmnt * 100),
                            name: storeInfo && storeInfo.storeName ? storeInfo.storeName : '',
                            image: this.configService.environment.razorpay.image,
                            prefill: {
                                method: method,
                                contact: this.userService.getPhoneNo() || '',
                                name: this.userService.getUserName() || '',
                                email: this.userService.getUserEmail() || 'xyz@gmail.com',
                            },
                            theme: this.configService.environment.razorpay.theme,
                            modal: {
                                ondismiss: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    return tslib_1.__generator(this, function (_a) {
                                        this.loading.dismiss();
                                        this.presentFailureAlert('razorpay');
                                        return [2 /*return*/];
                                    });
                                }); }
                            },
                            handler: function (handlerResponse) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var verifySignature, apiBody, txnRes, verifySignatureRes, paymentDetails, saveOrderPaymentDetails;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            console.log('handlerResponse', handlerResponse);
                                            verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
                                            apiBody = {
                                                razorpay_payment_id: handlerResponse.razorpay_payment_id,
                                                razorpay_order_id: handlerResponse.razorpay_order_id,
                                                razorpay_signature: handlerResponse.razorpay_signature,
                                            };
                                            txnRes = { paymentId: apiBody.razorpay_payment_id };
                                            return [4 /*yield*/, this.updateRazorpayPaymentId(order.id, txnRes)];
                                        case 1:
                                            _a.sent();
                                            return [4 /*yield*/, verifySignature(apiBody)];
                                        case 2:
                                            verifySignatureRes = _a.sent();
                                            console.log('verifySignatureRes', verifySignatureRes);
                                            if (verifySignatureRes.data.signatureIsValid) {
                                                paymentDetails = {
                                                    order: order,
                                                    mode: 'razorpay',
                                                    txnRes: txnRes,
                                                    amount: options_1.amount
                                                };
                                                saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                                                saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                    var paymentChatMsg;
                                                    return tslib_1.__generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0:
                                                                if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                                                this.loading.dismiss();
                                                                paymentChatMsg = {
                                                                    author: 'user',
                                                                    createdAt: new Date(),
                                                                    isRead: true,
                                                                    orderId: order.orderId,
                                                                    published: true,
                                                                    status: 'PaymentMsg',
                                                                    type: 'order',
                                                                    paymentMode: 'razorpay'
                                                                };
                                                                return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order)];
                                                            case 1:
                                                                _a.sent();
                                                                this.presentAlert('Payment is successful!');
                                                                _a.label = 2;
                                                            case 2: return [2 /*return*/];
                                                        }
                                                    });
                                                }); });
                                            }
                                            else {
                                                this.loading.dismiss();
                                                this.presentFailureAlert('razorpay');
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); },
                        };
                        razorpay = new Razorpay(options_1);
                        razorpay.open();
                        razorpay.on('payment.failed', function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var paymentId, txnRes;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log('failure response', response);
                                        this.loading.dismiss();
                                        paymentId = response.error && response.error.metadata && response.error.metadata.payment_id ? response.error.metadata.payment_id : '';
                                        if (!paymentId) return [3 /*break*/, 2];
                                        txnRes = { paymentId: paymentId };
                                        return [4 /*yield*/, this.updateRazorpayPaymentId(order.id, txnRes)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2:
                                        this.presentFailureAlert('razorpay');
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading.dismiss();
                        this.presentFailureAlert('razorpay');
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        this.events.publish('order:paymentFailure');
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.payWithPaytm = function (order) {
        var _this = this;
        var mobileNo = this.userService.getPhoneNo().slice(3);
        //// console.log(typeof mobileNo, mobileNo);
        var payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
        var getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
        getCheckSum({
            orderId: order.orderId.toString(),
            customerId: order.userId,
            phoneNo: mobileNo,
            txnAmount: payableAmnt.toString(),
        }).then(function (result) {
            //// console.log('checksum:', result.data.checksum);
            var paytmParams = {
                MID: result.data.mid,
                ORDER_ID: order.orderId.toString(),
                CUST_ID: order.userId,
                CHANNEL_ID: _this.configService.environment.paytm.CHANNEL_ID,
                TXN_AMOUNT: payableAmnt.toString(),
                WEBSITE: _this.configService.environment.paytm.WEBSITE,
                CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + order.orderId,
                INDUSTRY_TYPE_ID: _this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                MOBILE_NO: mobileNo,
                CHECKSUMHASH: result.data.checksum,
                ENVIRONMENT: _this.configService.environment.paytm.ENVIRONMENT
            };
            var successCallback = function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var paymentDetails, saveOrderPaymentDetails;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(response.STATUS == "TXN_SUCCESS")) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.presentLoading()];
                        case 1:
                            _a.sent();
                            paymentDetails = {
                                order: order,
                                mode: 'paytm',
                                txnRes: response,
                                amount: paytmParams.TXN_AMOUNT
                            };
                            saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                            saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var paymentChatMsg;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                            this.loading.dismiss();
                                            paymentChatMsg = {
                                                author: 'user',
                                                createdAt: new Date(),
                                                isRead: true,
                                                orderId: order.orderId,
                                                published: true,
                                                status: 'PaymentMsg',
                                                type: 'order',
                                                paymentMode: 'paytm'
                                            };
                                            return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order)];
                                        case 1:
                                            _a.sent();
                                            this.presentAlert('Payment is successful!');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); });
                            return [3 /*break*/, 3];
                        case 2:
                            this.presentFailureAlert();
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            var failureCallback = function (error) {
                _this.presentFailureAlert();
            };
            paytm.startPayment(paytmParams, successCallback, failureCallback);
        }).catch(function (error) {
            var code = error.code;
            var message = error.message;
            var details = error.details;
            //// console.log("Error", code, message, details);
            this.presentFailureAlert();
        });
    };
    OrderService.prototype.sendPaymentRequest = function (orderId, userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentReqMsg;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paymentReqMsg = {
                        author: 'admin',
                        createdAt: new Date(),
                        isRead: true,
                        orderId: orderId,
                        published: true,
                        status: 'PaymentRequest',
                        type: 'order'
                    };
                    this.events.publish('chat:sendMsg', paymentReqMsg, userId);
                    this.events.publish('order:sendPaymentRequestSuccess');
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.payWithCash = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentWithCash;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paymentWithCash = firebase.functions().httpsCallable('payments-paymentWithCash');
                    paymentWithCash(order).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.paymentChatMsgs('cash', order)];
                                case 1:
                                    _a.sent();
                                    this.events.publish('order:modeSetToCashSuccess');
                                    return [3 /*break*/, 3];
                                case 2:
                                    this.presentFailureAlert();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.updatePaymentComplete = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({
                                payment: {
                                    completed: true,
                                    mode: 'cash'
                                }
                            })];
                    case 2:
                        _a.sent();
                        this.events.publish('order:updatePaymentCompleteSuccess');
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.completePaymentWithWallet = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentWithWallet;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paymentWithWallet = firebase.functions().httpsCallable('payments-completePaymentWithWallet');
                    paymentWithWallet(order).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var paymentChatMsg;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                    this.events.publish('order:completePaymentWithWalletSuccess');
                                    paymentChatMsg = {
                                        author: 'user',
                                        createdAt: new Date(),
                                        isRead: true,
                                        orderId: order.orderId,
                                        published: true,
                                        status: 'PaymentMsg',
                                        type: 'order',
                                        paymentMode: 'wallet'
                                    };
                                    return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order)];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 3];
                                case 2:
                                    this.presentFailureAlert();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.paymentChatMsgs = function (chatObj, order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var chatRef, chatSnap, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        chatRef = this.afs.collection('chats').doc(order.userId).collection('messages', function (ref) { return ref
                            .where('orderId', '==', order.orderId)
                            .where('status', 'in', ['Confirmed', 'PaymentRequest']); });
                        return [4 /*yield*/, chatRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        chatSnap = _a.sent();
                        index = 0;
                        _a.label = 2;
                    case 2:
                        if (!(index < chatSnap.length)) return [3 /*break*/, 5];
                        //// console.log('msg id of do payment', chatSnap[index].id);
                        return [4 /*yield*/, this.afs.collection('chats').doc(order.userId).collection('messages').doc(chatSnap[index].id).delete()];
                    case 3:
                        //// console.log('msg id of do payment', chatSnap[index].id);
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        index++;
                        return [3 /*break*/, 2];
                    case 5:
                        this.events.publish('chat:sendMsg', chatObj, order.userId);
                        return [2 /*return*/];
                }
            });
        });
    };
    //Auto confrim order functions
    OrderService.prototype.ac_payWithRazorPay = function (order, razorpayId, method) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId_1, listOfCommentImages, uploads, payableAmnt, createOrderInRazorpay, createOrderRes, razorpayOrderId, storeInfo, options_2, razorpay, error_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 9, , 10]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        order['createdAt'] = new Date();
                        orderId_1 = this.afs.collection('orders').ref.doc().id;
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId_1, order.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3:
                        if (!order.userName) {
                            order.userName = order.address && order.address.name ? order.address.name : '';
                        }
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId_1).set(order)];
                    case 4:
                        _a.sent();
                        order['orderDocId'] = orderId_1;
                        this.addCommentImgs(listOfCommentImages, orderId_1);
                        this.clearProductsInCartIfAny(order.userId);
                        payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                        createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
                        return [4 /*yield*/, createOrderInRazorpay({ amount: Math.round(payableAmnt * 100), orderDocId: orderId_1 })];
                    case 5:
                        createOrderRes = _a.sent();
                        razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
                        console.log('razorpayOrderId', razorpayOrderId);
                        if (!razorpayOrderId) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.storage.get('storeInfo')];
                    case 6:
                        storeInfo = _a.sent();
                        options_2 = {
                            order_id: razorpayOrderId,
                            description: this.configService.environment.razorpay.description,
                            currency: this.configService.environment.razorpay.currency,
                            key: razorpayId,
                            amount: Math.round(payableAmnt * 100),
                            name: storeInfo && storeInfo.storeName ? storeInfo.storeName : '',
                            image: this.configService.environment.razorpay.image,
                            prefill: {
                                method: method,
                                contact: this.userService.getPhoneNo() || '',
                                name: this.userService.getUserName() || '',
                                email: this.userService.getUserEmail() || 'xyz@gmail.com',
                            },
                            theme: this.configService.environment.razorpay.theme,
                            modal: {
                                ondismiss: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    return tslib_1.__generator(this, function (_a) {
                                        this.loading.dismiss();
                                        // await this.paymentFailedUpdate(order.orderDocId);
                                        this.presentFailureAlert('razorpay');
                                        return [2 /*return*/];
                                    });
                                }); }
                            },
                            handler: function (handlerResponse) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var verifySignature, apiBody, txnRes, verifySignatureRes, paymentDetails, ac_saveOrderPaymentDetails;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            console.log('handlerResponse', handlerResponse);
                                            verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
                                            apiBody = {
                                                razorpay_payment_id: handlerResponse.razorpay_payment_id,
                                                razorpay_order_id: handlerResponse.razorpay_order_id,
                                                razorpay_signature: handlerResponse.razorpay_signature,
                                            };
                                            txnRes = { paymentId: apiBody.razorpay_payment_id };
                                            return [4 /*yield*/, this.updateRazorpayPaymentId(order.orderDocId, txnRes)];
                                        case 1:
                                            _a.sent();
                                            return [4 /*yield*/, verifySignature(apiBody)];
                                        case 2:
                                            verifySignatureRes = _a.sent();
                                            console.log('verifySignatureRes', verifySignatureRes);
                                            if (verifySignatureRes.data.signatureIsValid) {
                                                paymentDetails = {
                                                    order: order,
                                                    mode: 'razorpay',
                                                    txnRes: txnRes,
                                                    amount: options_2.amount
                                                };
                                                ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                                                ac_saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                    return tslib_1.__generator(this, function (_a) {
                                                        if (res.data.status && res.data.status === 'success') {
                                                            this.loading.dismiss();
                                                            this.presentAlert('Order has been placed successfully!');
                                                        }
                                                        else {
                                                            // await this.paymentFailedUpdate(order.orderDocId);
                                                            this.loading.dismiss();
                                                            this.presentFailureAlert('razorpay');
                                                        }
                                                        return [2 /*return*/];
                                                    });
                                                }); });
                                            }
                                            else {
                                                this.loading.dismiss();
                                                // await this.paymentFailedUpdate(order.orderDocId);
                                                this.presentFailureAlert('razorpay');
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); },
                        };
                        razorpay = new Razorpay(options_2);
                        razorpay.open();
                        razorpay.on('payment.failed', function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var paymentId, txnRes;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log('inside payment.failed');
                                        if (!(!response.error && response.error.description && response.error.description.includes('undefined'))) return [3 /*break*/, 3];
                                        console.log('failure response', response);
                                        this.loading.dismiss();
                                        paymentId = response.error && response.error.metadata && response.error.metadata.payment_id ? response.error.metadata.payment_id : '';
                                        if (!paymentId) return [3 /*break*/, 2];
                                        txnRes = { paymentId: paymentId };
                                        return [4 /*yield*/, this.updateRazorpayPaymentId(orderId_1, txnRes)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2:
                                        // await this.paymentFailedUpdate(order.orderDocId);
                                        this.presentFailureAlert('razorpay');
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 8];
                    case 7:
                        this.loading.dismiss();
                        // await this.paymentFailedUpdate(order.orderDocId);
                        this.presentFailureAlert('razorpay');
                        _a.label = 8;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.updateRazorpayPaymentId = function (orderId, txnRes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({
                            'payment.details': txnRes,
                            'payment.mode': 'razorpay'
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.paymentFailedUpdate = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({
                                'payment.completed': false,
                                'payment.status': 'failed',
                                walletAmount: 0,
                                cashbackAmount: 0,
                                extraChargeOnPayment: {
                                    charge: 0
                                },
                                status: 'Pending'
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_payWithPaytm = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mobileNo, orderId, listOfCommentImages, uploads, orderRefId, payableAmnt, getCheckSum;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        mobileNo = this.userService.getPhoneNo().slice(3);
                        orderId = this.afs.collection('orders').ref.doc().id;
                        order['createdAt'] = new Date();
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 4:
                        _a.sent();
                        order['orderDocId'] = orderId;
                        orderRefId = order.orderDocId;
                        this.addCommentImgs(listOfCommentImages, orderId);
                        payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                        getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
                        getCheckSum({
                            orderId: orderRefId.toString(),
                            customerId: order.userId,
                            phoneNo: mobileNo,
                            txnAmount: payableAmnt.toString(),
                        }).then(function (result) {
                            var paytmParams = {
                                MID: result.data.mid,
                                ORDER_ID: orderRefId.toString(),
                                CUST_ID: order.userId,
                                CHANNEL_ID: _this.configService.environment.paytm.CHANNEL_ID,
                                TXN_AMOUNT: payableAmnt.toString(),
                                WEBSITE: _this.configService.environment.paytm.WEBSITE,
                                CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + orderRefId.toString(),
                                INDUSTRY_TYPE_ID: _this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                                MOBILE_NO: mobileNo,
                                CHECKSUMHASH: result.data.checksum,
                                ENVIRONMENT: _this.configService.environment.paytm.ENVIRONMENT
                            };
                            var successCallback = function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var paymentDetails, ac_saveOrderPaymentDetails;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!(response.STATUS == "TXN_SUCCESS")) return [3 /*break*/, 1];
                                            paymentDetails = {
                                                order: order,
                                                mode: 'paytm',
                                                txnRes: response,
                                                amount: paytmParams.TXN_AMOUNT
                                            };
                                            ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                                            ac_saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                return tslib_1.__generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                                            this.loading.dismiss();
                                                            this.presentAlert('Order has been placed successfully!');
                                                            this.clearProductsInCartIfAny(order.userId);
                                                            return [3 /*break*/, 3];
                                                        case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                                        case 2:
                                                            _a.sent();
                                                            this.loading.dismiss();
                                                            this.presentFailureAlert();
                                                            _a.label = 3;
                                                        case 3: return [2 /*return*/];
                                                    }
                                                });
                                            }); });
                                            return [3 /*break*/, 3];
                                        case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                        case 2:
                                            _a.sent();
                                            this.loading.dismiss();
                                            this.presentFailureAlert();
                                            _a.label = 3;
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); };
                            var failureCallback = function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                        case 1:
                                            _a.sent();
                                            this.loading.dismiss();
                                            this.presentFailureAlert();
                                            return [2 /*return*/];
                                    }
                                });
                            }); };
                            paytm.startPayment(paytmParams, successCallback, failureCallback);
                        }).catch(function (error) {
                            var code = error.code;
                            var message = error.message;
                            var details = error.details;
                            //// console.log("Error", code, message, details);
                            this.presentFailureAlert();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_completePaymentWithWallet = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId, listOfCommentImages, uploads, orderPaymentWithWallet, error_5;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        orderId = this.afs.collection('orders').ref.doc().id;
                        order['createdAt'] = new Date();
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 1:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 3:
                        _a.sent();
                        order['orderDocId'] = orderId;
                        this.addCommentImgs(listOfCommentImages, orderId);
                        orderPaymentWithWallet = firebase.functions().httpsCallable('wallet-orderPaymentWithWallet');
                        orderPaymentWithWallet(order).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                        this.events.publish('order:ac_completePaymentWithWalletSuccess');
                                        this.clearProductsInCartIfAny(order.userId);
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                    case 2:
                                        _a.sent();
                                        this.presentFailureAlert();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 5];
                    case 4:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        this.presentFailureAlert();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.clearProductsInCartIfAny = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.storage.get('buyNowOrder').then(function (val) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var cartRef, cartData, i;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!!val) return [3 /*break*/, 6];
                                cartRef = this.afs.collection('users').doc(userId).collection('cart');
                                return [4 /*yield*/, cartRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                        var data = a.payload.doc.data();
                                        var id = a.payload.doc.id;
                                        return tslib_1.__assign({ id: id }, data);
                                    }); })).pipe(first()).toPromise()];
                            case 1:
                                cartData = _a.sent();
                                i = 0;
                                _a.label = 2;
                            case 2:
                                if (!(i < cartData.length)) return [3 /*break*/, 5];
                                return [4 /*yield*/, this.cartService.deleteCartProduct(cartData[i].id)];
                            case 3:
                                _a.sent();
                                _a.label = 4;
                            case 4:
                                i++;
                                return [3 /*break*/, 2];
                            case 5:
                                this.storage.set('cart', []);
                                _a.label = 6;
                            case 6: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.ac_payWithCash = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listOfCommentImages_1, ac_paymentWithCash;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    order['payment'] = {
                        completed: false,
                        mode: 'cash',
                        details: {
                            amount: order.totalAmountToPaid - order.walletAmount - order.cashbackAmount
                        }
                    };
                    listOfCommentImages_1 = order['listOfCommentImages'];
                    ac_paymentWithCash = firebase.functions().httpsCallable('payments-ac_paymentWithCash');
                    ac_paymentWithCash(order).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var orderId, uploads;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 4];
                                    orderId = res.data.orderDocId;
                                    if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                                    return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                                case 1:
                                    uploads = _a.sent();
                                    return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({
                                            "uploadedDoc.uploads": uploads
                                        })];
                                case 2:
                                    _a.sent();
                                    _a.label = 3;
                                case 3:
                                    this.addCommentImgs(listOfCommentImages_1, orderId);
                                    this.events.publish('order:ac_modeSetToCashSuccess');
                                    this.clearProductsInCartIfAny(order.userId);
                                    return [3 /*break*/, 5];
                                case 4:
                                    this.presentFailureAlert();
                                    _a.label = 5;
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    // paytm web...
    OrderService.prototype.ac_paytmWebInitiateTxn = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRefId_1, initiateParams, initiateTxn;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    orderRefId_1 = this.afs.collection('orders').ref.doc().id;
                    initiateParams = {
                        orderId: orderRefId_1.toString(),
                        customerId: order.userId,
                        txnAmount: (order.totalAmountToPaid - order.walletAmount - order.cashbackAmount).toString()
                    };
                    initiateTxn = firebase.functions().httpsCallable('payments-initiateTxnApi');
                    initiateTxn(initiateParams).then(function (res) {
                        //// console.log('token...', res.data);
                        _this.events.publish('order:initiateTxnSuccess', res.data, orderRefId_1.toString());
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('loading');
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: 'Please Wait...',
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_payWithUPIManual = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId, listOfCommentImages, uploads, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        orderId = this.afs.collection('orders').ref.doc().id;
                        order['createdAt'] = new Date();
                        order['status'] = 'Pending';
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 4:
                        _a.sent();
                        order['orderDocId'] = orderId;
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(order.userId);
                        this.loading.dismiss();
                        return [2 /*return*/, ({ order: order })];
                    case 5:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        error_6['location'] = 'order-service:ac_payWithUPIManual';
                        this.logglyService.log(error_6);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_completeUPIManualPayment = function (order, paymentImg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId, orderRef, orderData, imgRef, downloadURL, payment, walletObj, updateUserWalletAmount, error_7;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 8, , 9]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        orderId = '';
                        if (!order.hasOwnProperty('orderDocId')) return [3 /*break*/, 2];
                        orderId = order.orderDocId;
                        return [3 /*break*/, 4];
                    case 2:
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', order.orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 3:
                        orderData = _a.sent();
                        orderId = orderData[0].id;
                        _a.label = 4;
                    case 4:
                        imgRef = this.fbStorage.ref("upiManualPayment/" + orderId + "/image/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(paymentImg, 'data_url')];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                    case 6:
                        downloadURL = _a.sent();
                        payment = {
                            completed: true,
                            mode: 'upiManual',
                            screenshot: downloadURL,
                            status: 'completed'
                        };
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({
                                payment: payment,
                                status: 'Confirmed',
                                scheduledDate: order.scheduledDate ? new Date(order.scheduledDate) : '',
                                scheduledTime: order.scheduledTime
                            })];
                    case 7:
                        _a.sent();
                        this.loading.dismiss();
                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                        if (order.walletAmount > 0 || order.cashbackAmount > 0) {
                            walletObj = {
                                walletAmount: order.walletAmount,
                                cashbackAmount: order.cashbackAmount,
                                orderId: orderId,
                                userId: order.userId
                            };
                            updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
                            updateUserWalletAmount(walletObj).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    console.log(res);
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        return [3 /*break*/, 9];
                    case 8:
                        error_7 = _a.sent();
                        console.dir(error_7);
                        error_7['location'] = 'order-service:ac_completeUPIManualPayment';
                        this.logglyService.log(error_7);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    // async ac_completeUPIManualPayment(order, paymentImg) {
    //     try {
    //         await this.presentLoading();
    //         order['createdAt'] = new Date();
    //         order['status'] = 'Confirmed';
    //         order['scheduledDate'] = order.scheduledDate ? new Date(order.scheduledDate) : '';
    //         order['scheduledTime'] = order.scheduledTime;
    //         let orderId = '';
    //         if (!order.orderId) {
    //             orderId = this.afs.collection('orders').ref.doc().id;
    //             if (order.uploadedDoc.uploads.length !== 0) {
    //                 const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
    //                 order.uploadedDoc['uploads'] = uploads;
    //             }
    //         } else {
    //             const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', order.orderId));
    //             const orderData: any = await orderRef.snapshotChanges().pipe(
    //                 map(actions => actions.map(a => {
    //                     const data = a.payload.doc.data();
    //                     const id = a.payload.doc.id;
    //                     return { id, ...data as {} };
    //                 }))
    //             ).pipe(first()).toPromise();
    //             orderId = orderData[0].id;
    //         }
    //         const imgRef: any = this.angularFireStorage.ref(`upiManualPayment/${orderId}/image/` + new Date().getTime().toString() + '.png');
    //         await imgRef.putString(paymentImg, 'data_url');
    //         const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
    //         order['payment'] = {
    //             completed: true,
    //             mode: 'upiManual',
    //             screenshot: downloadURL,
    //             status: 'completed'
    //         }
    //         if (!order.orderId) {
    //             const listOfCommentImages = { ...order['listOfCommentImages'] };
    //             delete order['listOfCommentImages'];
    //             await this.afs.collection('orders').doc(orderId).set(order);
    //             this.addCommentImgs(listOfCommentImages, orderId);
    //             this.clearProductsInCartIfAny(order.userId);
    //         } else {
    //             await this.afs.collection('orders').doc(orderId).update(order);
    //         }
    //         this.loading.dismiss();
    //         this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
    //         if (order.walletAmount > 0 || order.cashbackAmount > 0) {
    //             const walletObj = {
    //                 walletAmount: order.walletAmount,
    //                 cashbackAmount: order.cashbackAmount,
    //                 orderId: orderId,
    //                 userId: order.userId
    //             }
    //             let updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
    //             updateUserWalletAmount(walletObj).then(async (res) => {
    //                 console.log(res);
    //             });
    //         }
    //     } catch (error) {
    //         console.dir(error);
    //         error['location'] = 'order-service:ac_completeUPIManualPayment';
    //         this.logglyService.log(error);
    //     }
    // }
    OrderService.prototype.presentAlert = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            backdropDismiss: false,
                            buttons: [{
                                    text: 'OK',
                                    handler: function () {
                                        _this.modalController.dismiss();
                                        _this.navController.navigateRoot(['order-successful']);
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.presentFailureAlert = function (mode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.loading) {
                            this.loading.dismiss();
                        }
                        return [4 /*yield*/, this.alertController.create({
                                message: mode === 'razorpay' ? 'We are verifying your payment status. It may take upto 15 minutes. Please check order details from my orders for further information'
                                    : 'Payment is failed! Any amount debited will be refunded in 4 - 5 working days.',
                                backdropDismiss: false,
                                buttons: [{
                                        text: 'Ok',
                                        handler: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                            var isExternalOrder;
                                            return tslib_1.__generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this.storage.get('isExternalOrder')];
                                                    case 1:
                                                        isExternalOrder = _a.sent();
                                                        if (isExternalOrder) {
                                                            this.navController.navigateRoot(['order-failure']);
                                                        }
                                                        else {
                                                            this.navController.navigateRoot(['user-order-history']);
                                                        }
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); }
                                    }]
                            })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.addCommentImgs = function (listOfCommentImages, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, pid, imgs, _b, imgs_1, img, imgRef;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(Object.keys(listOfCommentImages).length !== 0)) return [3 /*break*/, 6];
                        _i = 0, _a = Object.keys(listOfCommentImages);
                        _c.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 6];
                        pid = _a[_i];
                        imgs = listOfCommentImages[pid];
                        _b = 0, imgs_1 = imgs;
                        _c.label = 2;
                    case 2:
                        if (!(_b < imgs_1.length)) return [3 /*break*/, 5];
                        img = imgs_1[_b];
                        imgRef = this.fbStorage.ref("ordersCommentImgs/" + orderId + "/images/" + pid + "/" + new Date().getTime() + '.png');
                        return [4 /*yield*/, imgRef.putString(img, 'data_url')];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        _b++;
                        return [3 /*break*/, 2];
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.placeOrder = function (products, listOfCommentImages, address, paymentObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var finalProducts, orderObj, orderId, uploads, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        finalProducts = products.map(function (_a) {
                            var cartUpdateTimer = _a.cartUpdateTimer, priceSlabs = _a.priceSlabs, priceStatus = _a.priceStatus, stopWhenNoQty = _a.stopWhenNoQty, templateId = _a.templateId, totalQty = _a.totalQty, keepAttrs = tslib_1.__rest(_a, ["cartUpdateTimer", "priceSlabs", "priceStatus", "stopWhenNoQty", "templateId", "totalQty"]);
                            return keepAttrs;
                        });
                        paymentObj['products'] = finalProducts;
                        paymentObj['address'] = address;
                        return [4 /*yield*/, this.getOrderObject(paymentObj)];
                    case 1:
                        orderObj = _a.sent();
                        orderId = this.afs.collection('orders').ref.doc().id;
                        if (!(orderObj.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, orderObj.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        orderObj.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3:
                        console.log('orderObj:', orderObj);
                        if (!orderObj.userName) {
                            orderObj.userName = orderObj.address && orderObj.address.name ? orderObj.address.name : '';
                        }
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(orderObj)];
                    case 4:
                        _a.sent();
                        if (!address.lat) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({
                                deliveryLatLng: {
                                    lat: address.lat,
                                    lng: address.lng
                                }
                            })];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(orderObj.userId);
                        this.events.publish('order:orderSuccessfullyPlaced');
                        return [3 /*break*/, 8];
                    case 7:
                        error_8 = _a.sent();
                        console.dir(error_8);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.autoConfirmPlaceOrder = function (products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var finalProducts, orderObj, error_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        finalProducts = products.map(function (_a) {
                            var cartUpdateTimer = _a.cartUpdateTimer, priceSlabs = _a.priceSlabs, priceStatus = _a.priceStatus, stopWhenNoQty = _a.stopWhenNoQty, templateId = _a.templateId, totalQty = _a.totalQty, keepAttrs = tslib_1.__rest(_a, ["cartUpdateTimer", "priceSlabs", "priceStatus", "stopWhenNoQty", "templateId", "totalQty"]);
                            return keepAttrs;
                        });
                        paymentObj['products'] = finalProducts;
                        paymentObj['address'] = address;
                        return [4 /*yield*/, this.getOrderObject(paymentObj)];
                    case 1:
                        orderObj = _a.sent();
                        orderObj['status'] = 'Pending';
                        orderObj['payment']['status'] = 'pending';
                        if (address.lat) {
                            orderObj['deliveryLatLng'] = {
                                lat: address.lat,
                                lng: address.lng
                            };
                        }
                        orderObj['listOfCommentImages'] = listOfCommentImages;
                        orderObj['isCodAvailableForCoupon'] = isCodAvailableForCoupon;
                        this.events.publish('order:autoConfirmPlaceOrderSuccess', orderObj);
                        return [3 /*break*/, 3];
                    case 2:
                        error_9 = _a.sent();
                        console.dir(error_9);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.getOrderObject = function (paymentObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var region, userId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get('region')];
                    case 1:
                        region = _a.sent();
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 2:
                        userId = _a.sent();
                        return [2 /*return*/, tslib_1.__assign({}, paymentObj, { orderId: null, status: 'Pending', createdAt: new Date(), payment: {
                                    completed: false,
                                    mode: null,
                                    details: null
                                }, userId: userId, msgId: this.afs.collection('chats').doc(userId).collection('messages').ref.doc().id, userName: this.userService.getUserName(), discount: 0, region: region && region.name ? region.name : '', vendorId: region && region.vendorId ? region.vendorId : '' })];
                }
            });
        });
    };
    OrderService.prototype.getUploadedDocUrls = function (orderId, uploads) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var _i, uploads_1, img, imgRef, _a;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _i = 0, uploads_1 = uploads;
                                    _b.label = 1;
                                case 1:
                                    if (!(_i < uploads_1.length)) return [3 /*break*/, 5];
                                    img = uploads_1[_i];
                                    imgRef = this.angularFireStorage.ref("orders/" + orderId + "/uploadedDoc/" + new Date().getTime() + '.png');
                                    return [4 /*yield*/, imgRef.putString(img.url, 'data_url')];
                                case 2:
                                    _b.sent();
                                    _a = img;
                                    return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                                case 3:
                                    _a.url = _b.sent();
                                    _b.label = 4;
                                case 4:
                                    _i++;
                                    return [3 /*break*/, 1];
                                case 5:
                                    resolve(uploads);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    OrderService.prototype.saveResaleData = function (resale, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var error_10;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({ resale: resale })];
                                case 1:
                                    _a.sent();
                                    resolve(true);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_10 = _a.sent();
                                    console.dir(error_10);
                                    error_10['location'] = 'user-service:saveResaleData';
                                    this.logglyService.log(error_10);
                                    resolve(false);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    OrderService.prototype.ac_completeCustomOptionPayment = function (order, userResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId, uploads, orderRef, orderData, downloadURL, imgRef, listOfCommentImages, walletObj, updateUserWalletAmount, error_11;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 14, , 15]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        order['createdAt'] = new Date();
                        order['status'] = 'Confirmed';
                        order['scheduledDate'] = order.scheduledDate ? new Date(order.scheduledDate) : '';
                        order['scheduledTime'] = order.scheduledTime;
                        orderId = '';
                        if (!!order.orderId) return [3 /*break*/, 4];
                        orderId = this.afs.collection('orders').ref.doc().id;
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [3 /*break*/, 6];
                    case 4:
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', order.orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 5:
                        orderData = _a.sent();
                        orderId = orderData[0].id;
                        _a.label = 6;
                    case 6:
                        downloadURL = '';
                        if (!userResponse.image) return [3 /*break*/, 9];
                        imgRef = this.fbStorage.ref("customPayment/" + orderId + "/image/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(userResponse.image, 'data_url')];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                    case 8:
                        downloadURL = _a.sent();
                        _a.label = 9;
                    case 9:
                        order['payment'] = {
                            completed: true,
                            mode: 'custom',
                            optionName: userResponse.optionName,
                            screenshot: downloadURL,
                            textDetails: userResponse.textDetails,
                            status: 'completed'
                        };
                        if (!!order.orderId) return [3 /*break*/, 11];
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 10:
                        _a.sent();
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(order.userId);
                        return [3 /*break*/, 13];
                    case 11: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update(order)];
                    case 12:
                        _a.sent();
                        _a.label = 13;
                    case 13:
                        this.loading.dismiss();
                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                        if (order.walletAmount > 0 || order.cashbackAmount > 0) {
                            walletObj = {
                                walletAmount: order.walletAmount,
                                cashbackAmount: order.cashbackAmount,
                                orderId: orderId,
                                userId: order.userId
                            };
                            updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
                            updateUserWalletAmount(walletObj).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    console.log(res);
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        return [3 /*break*/, 15];
                    case 14:
                        error_11 = _a.sent();
                        console.dir(error_11);
                        error_11['location'] = 'order-service:ac_completeCustomOptionPayment';
                        this.logglyService.log(error_11);
                        return [3 /*break*/, 15];
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_payWithStripe = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var order_1, orderId, listOfCommentImages, uploads, payableAmnt_1, stripeData, payWithStripe, error_12;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        order_1 = data.order;
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        orderId = this.afs.collection('orders').ref.doc().id;
                        order_1['createdAt'] = new Date();
                        listOfCommentImages = tslib_1.__assign({}, order_1['listOfCommentImages']);
                        delete order_1['listOfCommentImages'];
                        if (!(order_1.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order_1.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order_1.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order_1)];
                    case 4:
                        _a.sent();
                        order_1['orderDocId'] = orderId;
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(order_1.userId);
                        payableAmnt_1 = order_1.partialPayment.status ? order_1.partialPayment.online.amount : (order_1.totalAmountToPaid - (order_1.walletAmount + order_1.cashbackAmount));
                        stripeData = {
                            amount: payableAmnt_1 * 100,
                            currency: this.configService.environment.currencyCode,
                            token: data.token
                        };
                        payWithStripe = firebase.functions().httpsCallable('payments-payWithStripe');
                        payWithStripe(stripeData).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var paymentDetails, ac_saveOrderPaymentDetails;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log('res of pay with stripe', res);
                                        if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                        paymentDetails = {
                                            order: order_1,
                                            mode: 'stripe',
                                            txnRes: res.data.txnRes,
                                            amount: payableAmnt_1
                                        };
                                        ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                                        ac_saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                            return tslib_1.__generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                                        this.loading.dismiss();
                                                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                                                        return [3 /*break*/, 3];
                                                    case 1: return [4 /*yield*/, this.paymentFailedUpdate(order_1.orderDocId)];
                                                    case 2:
                                                        _a.sent();
                                                        this.loading.dismiss();
                                                        this.presentFailureAlert();
                                                        _a.label = 3;
                                                    case 3: return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, this.paymentFailedUpdate(order_1.orderDocId)];
                                    case 2:
                                        _a.sent();
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 6];
                    case 5:
                        error_12 = _a.sent();
                        console.dir(error_12);
                        error_12['location'] = 'order-service:ac_payWithRazorPay';
                        this.logglyService.log(error_12);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.payWithStripe = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var order_2, payableAmnt_2, stripeData, payWithStripe, error_13;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        order_2 = data.order;
                        payableAmnt_2 = order_2.partialPayment.status ? order_2.partialPayment.online.amount : (order_2.totalAmountToPaid - (order_2.walletAmount + order_2.cashbackAmount));
                        stripeData = {
                            amount: payableAmnt_2 * 100,
                            currency: this.configService.environment.currencyCode,
                            token: data.token
                        };
                        payWithStripe = firebase.functions().httpsCallable('payments-payWithStripe');
                        payWithStripe(stripeData).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var paymentDetails, saveOrderPaymentDetails;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                console.log('res of pay with stripe', res);
                                if (res.data.status && res.data.status === 'success') {
                                    paymentDetails = {
                                        order: order_2,
                                        mode: 'stripe',
                                        txnRes: res.data.txnRes,
                                        amount: payableAmnt_2
                                    };
                                    saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                                    saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        var paymentChatMsg;
                                        return tslib_1.__generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                                    paymentChatMsg = {
                                                        author: 'user',
                                                        createdAt: new Date(),
                                                        isRead: true,
                                                        orderId: order_2.orderId,
                                                        published: true,
                                                        status: 'PaymentMsg',
                                                        type: 'order',
                                                        paymentMode: 'stripe'
                                                    };
                                                    return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order_2)];
                                                case 1:
                                                    _a.sent();
                                                    this.loading.dismiss();
                                                    this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                                                    return [3 /*break*/, 3];
                                                case 2:
                                                    this.loading.dismiss();
                                                    this.presentFailureAlert();
                                                    _a.label = 3;
                                                case 3: return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                }
                                else {
                                    this.loading.dismiss();
                                    this.presentFailureAlert();
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_13 = _a.sent();
                        console.dir(error_13);
                        error_13['location'] = 'order-service:payWithRazorPay';
                        this.logglyService.log(error_13);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_prepareOrderForPayment = function (order, paymentType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId, listOfCommentImages, uploads, payableAmnt, error_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        orderId = this.afs.collection('orders').ref.doc().id;
                        order['createdAt'] = new Date();
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 4:
                        _a.sent();
                        order['orderDocId'] = orderId;
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(order.userId);
                        payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                        if (paymentType && (paymentType === 'paypal' || paymentType === 'cashfree' || paymentType === 'ccAvenue')) {
                            this.loading.dismiss();
                        }
                        return [2 /*return*/, { payableAmnt: payableAmnt, order: order }];
                    case 5:
                        error_14 = _a.sent();
                        console.dir(error_14);
                        error_14['location'] = 'order-service:prepareOrderForPayment';
                        this.logglyService.log(error_14);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_payWithPaypal = function (order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentDetails, ac_saveOrderPaymentDetails, error_15;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        paymentDetails = {
                            order: order,
                            mode: 'paypal',
                            txnRes: txnRes,
                            amount: payableAmnt
                        };
                        ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                        ac_saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                        this.loading.dismiss();
                                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                    case 2:
                                        _a.sent();
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_15 = _a.sent();
                        console.log(error_15);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.payWithPaypal = function (order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentDetails, saveOrderPaymentDetails, error_16;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        paymentDetails = {
                            order: order,
                            mode: 'paypal',
                            txnRes: txnRes,
                            amount: payableAmnt
                        };
                        saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                        saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var paymentChatMsg;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                        paymentChatMsg = {
                                            author: 'user',
                                            createdAt: new Date(),
                                            isRead: true,
                                            orderId: order.orderId,
                                            published: true,
                                            status: 'PaymentMsg',
                                            type: 'order',
                                            paymentMode: 'paypal'
                                        };
                                        return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order)];
                                    case 1:
                                        _a.sent();
                                        this.loading.dismiss();
                                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                                        return [3 /*break*/, 3];
                                    case 2:
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_16 = _a.sent();
                        console.dir(error_16);
                        error_16['location'] = 'order-service:payWithRazorPay';
                        this.logglyService.log(error_16);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_payWithCashfree = function (order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentDetails, ac_saveOrderPaymentDetails;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paymentDetails = {
                        order: order,
                        mode: 'cashfree',
                        txnRes: txnRes,
                        amount: payableAmnt
                    };
                    console.log('paymentDetails:', paymentDetails);
                    ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                    ac_saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                    this.loading.dismiss();
                                    this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                                    return [3 /*break*/, 3];
                                case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                case 2:
                                    _a.sent();
                                    this.loading.dismiss();
                                    this.presentFailureAlert();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    console.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.payWithCashfree = function (order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentDetails, saveOrderPaymentDetails;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paymentDetails = {
                        order: order,
                        mode: 'cashfree',
                        txnRes: txnRes,
                        amount: payableAmnt
                    };
                    console.log('paymentDetails from manual:', paymentDetails);
                    saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                    saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var paymentChatMsg;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                    paymentChatMsg = {
                                        author: 'user',
                                        createdAt: new Date(),
                                        isRead: true,
                                        orderId: order.orderId,
                                        published: true,
                                        status: 'PaymentMsg',
                                        type: 'order',
                                        paymentMode: 'cashfree'
                                    };
                                    return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order)];
                                case 1:
                                    _a.sent();
                                    this.loading.dismiss();
                                    this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                                    return [3 /*break*/, 3];
                                case 2:
                                    this.loading.dismiss();
                                    this.presentFailureAlert();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    console.dir(error);
                    error['location'] = 'order-service:payWithRazorPay';
                    this.logglyService.log(error);
                    this.loading.dismiss();
                    this.presentFailureAlert();
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.createOrderCashfree = function (orderData, autoConfirm) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var orderCashfree_1, payableAmount, _a, payableAmnt, order, payableAmnt, orderObj, createOrderCashfree, error_17;
                        var _this = this;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 4, , 5]);
                                    payableAmount = void 0;
                                    if (!autoConfirm) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.ac_prepareOrderForPayment(orderData, 'cashfree')];
                                case 1:
                                    _a = _b.sent(), payableAmnt = _a.payableAmnt, order = _a.order;
                                    payableAmount = payableAmnt;
                                    console.log('order:', order);
                                    orderCashfree_1 = order;
                                    return [3 /*break*/, 3];
                                case 2:
                                    payableAmnt = orderData.partialPayment.status ? orderData.partialPayment.online.amount : (orderData.totalAmountToPaid - (orderData.walletAmount + orderData.cashbackAmount));
                                    payableAmount = payableAmnt;
                                    orderCashfree_1 = orderData;
                                    _b.label = 3;
                                case 3:
                                    orderObj = {
                                        orderAmnt: parseFloat(payableAmount.toFixed(2)),
                                        userId: orderData.userId,
                                        source: 'website-v2',
                                        currencyCode: this.configService.environment.currencyCode,
                                        user: {
                                            id: orderData.userId,
                                            email: this.userService.getUserEmail() || 'xyz@gmail.com',
                                            phoneNo: this.userService.getPhoneNo() || ''
                                        }
                                    };
                                    createOrderCashfree = firebase.functions().httpsCallable('payments-createOrderCashfree');
                                    createOrderCashfree(orderObj).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        var cashfreeToken;
                                        return tslib_1.__generator(this, function (_a) {
                                            if (res.data) {
                                                cashfreeToken = res.data.order_token;
                                                // this.loading.dismiss();
                                                resolve({ cashfreeToken: cashfreeToken, orderCashfree: orderCashfree_1 });
                                            }
                                            else {
                                                // this.loading.dismiss();
                                                this.presentFailureAlert();
                                                resolve({});
                                            }
                                            return [2 /*return*/];
                                        });
                                    }); }).catch(function (error) {
                                        // this.loading.dismiss();
                                        _this.presentFailureAlert();
                                        resolve({});
                                        console.log('err:::::::::::', error);
                                    });
                                    return [3 /*break*/, 5];
                                case 4:
                                    error_17 = _b.sent();
                                    // this.loading.dismiss();
                                    this.presentFailureAlert();
                                    resolve({});
                                    console.log('err from catch:', error_17);
                                    return [3 /*break*/, 5];
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    OrderService.prototype.getOrderDetailsCashfree = function (cashfreeOrderId, orderData, autoConfirm) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var obj, getOrderDetailsCashfree, error_18;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        obj = {
                            cashfreeOrderId: cashfreeOrderId
                        };
                        getOrderDetailsCashfree = firebase.functions().httpsCallable('payments-getOrderDetailsCashfree');
                        getOrderDetailsCashfree(obj).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var txnRes;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log('res:', res);
                                        if (!res.data) return [3 /*break*/, 7];
                                        if (!(res.data.order_status == 'PAID')) return [3 /*break*/, 1];
                                        txnRes = {
                                            order_status: res.data.order_status,
                                            cf_order_id: res.data.cf_order_id,
                                            created_at: res.data.created_at,
                                            order_id: res.data.order_id,
                                            order_amount: res.data.order_amount,
                                        };
                                        if (autoConfirm) {
                                            this.ac_payWithCashfree(orderData, txnRes, txnRes.order_amount);
                                            console.log('successfully paid');
                                        }
                                        else {
                                            this.payWithCashfree(orderData, txnRes, txnRes.order_amount);
                                        }
                                        return [3 /*break*/, 6];
                                    case 1:
                                        if (!autoConfirm) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this.paymentFailedUpdate(orderData.orderDocId)];
                                    case 2:
                                        _a.sent();
                                        return [3 /*break*/, 5];
                                    case 3: return [4 /*yield*/, this.paymentFailedUpdate(orderData.id)];
                                    case 4:
                                        _a.sent();
                                        _a.label = 5;
                                    case 5:
                                        //console.log('inside failed with orderId:', cashfreeOrder);
                                        //await this.paymentFailedUpdate(cashfreeOrder.orderDocId);
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        console.log('not paid');
                                        _a.label = 6;
                                    case 6: return [3 /*break*/, 9];
                                    case 7: return [4 /*yield*/, this.paymentFailedUpdate(orderData.orderDocId)];
                                    case 8:
                                        _a.sent();
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        _a.label = 9;
                                    case 9: return [2 /*return*/];
                                }
                            });
                        }); }).catch(function (error) {
                            console.log('err:::::::::::', error);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_18 = _a.sent();
                        console.log('error:', error_18);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.cashfreePaymentFailed = function (autoConfirm, orderData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!autoConfirm) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.paymentFailedUpdate(orderData.orderDocId)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.paymentFailedUpdate(orderData.id)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // *** CCAvenue ***
    OrderService.prototype.createOrderCCAvenue = function (ccOrder, ccAvenueObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payableAmnt, order, ac_Data, registeredDomain, processCCAvenuePayload, paymentPayloadState, paymentPayload, form, prop, input, error_19;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        payableAmnt = void 0, order = void 0;
                        if (!ccOrder.autoConfirmOrder) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.ac_prepareOrderForPayment(ccOrder, 'ccAvenue')];
                    case 1:
                        ac_Data = _a.sent();
                        payableAmnt = ac_Data.payableAmnt;
                        order = ac_Data.order;
                        return [3 /*break*/, 3];
                    case 2:
                        if (!ccOrder.autoConfirmOrder) {
                            payableAmnt = ccOrder.partialPayment.status ? ccOrder.partialPayment.online.amount : (ccOrder.totalAmountToPaid - (ccOrder.walletAmount + ccOrder.cashbackAmount));
                            order = ccOrder;
                            order['orderDocId'] = ccOrder.id;
                        }
                        _a.label = 3;
                    case 3:
                        order['currencyCode'] = this.configService.environment.currencyCode;
                        registeredDomain = new URL(window.location.href).hostname;
                        console.log('registeredDomain : ', registeredDomain);
                        processCCAvenuePayload = firebase.functions().httpsCallable('payments-processCCAvenuePayload');
                        return [4 /*yield*/, processCCAvenuePayload({ orderData: order, credentials: ccAvenueObj, type: 'encrypt', amount: payableAmnt, source: 'website', registeredDomain: registeredDomain })];
                    case 4:
                        paymentPayloadState = _a.sent();
                        if (paymentPayloadState.data.status === 'success') {
                            console.log('Success in payload processing! : ', paymentPayloadState);
                        }
                        else {
                            console.log('Error in payload processing! : ', paymentPayloadState);
                            return [2 /*return*/];
                        }
                        paymentPayload = {
                            encRequest: paymentPayloadState.data.encryptedData,
                            access_code: ccAvenueObj.accessCode
                        };
                        form = this.document.createElement("form");
                        form.method = "POST";
                        form.target = "_self";
                        form.action = "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
                        for (prop in paymentPayload) {
                            input = this.document.createElement("input");
                            input.type = "hidden";
                            input.name = prop;
                            input.value = paymentPayload[prop];
                            form.append(input);
                        }
                        this.document.body.appendChild(form);
                        form.submit();
                        return [3 /*break*/, 6];
                    case 5:
                        error_19 = _a.sent();
                        console.log('error in createOrderCCAvenue : ', error_19);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.checkCCAvenueOID = function (docId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var orderRef;
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            try {
                                orderRef = this.afs.collection('orders').doc(docId);
                                orderRef.get().subscribe(function (doc) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    return tslib_1.__generator(this, function (_a) {
                                        if (doc.exists) {
                                            console.log('found order : ', doc.data());
                                            resolve(doc.data());
                                        }
                                        else {
                                            console.log('not found!');
                                            resolve({});
                                        }
                                        return [2 /*return*/];
                                    });
                                }); });
                            }
                            catch (error) {
                                console.log('error in checkCCAvenueOID!', error);
                                resolve({});
                            }
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    OrderService.prototype.checkCCAvenueOrderStatusTracker = function (docId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var ccAvenueOrderStatusTracker, OSTData, error_20;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    ccAvenueOrderStatusTracker = firebase.functions().httpsCallable('payments-ccAvenueOrderStatusTracker');
                                    return [4 /*yield*/, ccAvenueOrderStatusTracker({ oid: docId })];
                                case 1:
                                    OSTData = _a.sent();
                                    console.log('Response : ', OSTData.data);
                                    if (OSTData.data.status) {
                                        resolve({
                                            status: true
                                        });
                                    }
                                    else {
                                        resolve({
                                            status: false
                                        });
                                    }
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_20 = _a.sent();
                                    console.log('error in checkCCAvenueOID! : ', error_20);
                                    resolve({
                                        status: false
                                    });
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    OrderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderService_Factory() { return new OrderService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.AngularFirestore), i0.ɵɵinject(i4.UserService), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i6.ConfigService), i0.ɵɵinject(i7.LogglyLoggerService), i0.ɵɵinject(i8.LabelService), i0.ɵɵinject(i9.AngularFireStorage), i0.ɵɵinject(i10.CartService), i0.ɵɵinject(i9.AngularFireStorage), i0.ɵɵinject(i5.ActivatedRoute), i0.ɵɵinject(i11.DOCUMENT)); }, token: OrderService, providedIn: "root" });
    return OrderService;
}());
export { OrderService };
