import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Events, NavController, ModalController } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/fire/auth";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/fire/storage";
import * as i5 from "@ionic/storage";
import * as i6 from "@angular/router";
var UserService = /** @class */ (function () {
    function UserService(afs, fireAuth, events, fbStorage, storage, router, ngZone, navController, modalController) {
        this.afs = afs;
        this.fireAuth = fireAuth;
        this.events = events;
        this.fbStorage = fbStorage;
        this.storage = storage;
        this.router = router;
        this.ngZone = ngZone;
        this.navController = navController;
        this.modalController = modalController;
        this.userId = '';
        this.user = {
            name: null,
            email: null,
            phoneNo: null,
            createdAt: null,
            lastAccessAt: null,
            active: null,
            dP: null,
            role: null,
            vacations: null,
            paymentInfo: null,
            defaultAddress: null,
            readTerms: null,
            loginMode: '',
            setFromUI: true,
            wallet: {
                balance: 0,
                cashback: 0,
                lastTransactions: {}
            },
            lowercaseName: ''
        };
        this.orderMsg = {
            type: null,
            status: null,
            createdAt: null,
            author: null,
            isRead: null,
            published: null,
            products: null
        };
        this.msg = {
            type: null,
            message: null,
            createdAt: null,
            images: null,
            isRead: null,
            author: null,
            published: null,
            mob: null,
            thumb: null,
            imageCount: null
        };
        this.orders = [];
        this.usersDataForAdminProducts = [];
        this.pendingOrdersForAdmin = [];
        this.completedOrdersForAdmin = [];
        this.productsNeedToDeliverForAdmin = [];
    }
    UserService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('user:getUserInfo', function (uid) {
            _this.getUserInfo(uid);
        });
        this.events.subscribe('user:addUserImage', function (base64Image) {
            _this.addUserImage(base64Image);
        });
        this.events.subscribe('user:getUserDetails', function () {
            _this.getUserDetails();
        });
        this.events.subscribe('user:setActiveVacation', function (vacationDetails) {
            _this.setActiveVacation(vacationDetails);
        });
        this.events.subscribe('user:getVacationsDetails', function () {
            _this.getVacationsDetails();
        });
        this.events.subscribe('user:getAllUsers', function () {
            _this.getAllUsers();
        });
        this.events.subscribe('user:getUsersForAdminUsers', function () {
            _this.getUsersForAdminUsers();
        });
        this.events.subscribe('user:loadMoreUsersForAdminUsers', function () {
            _this.loadMoreUsersForAdminUsers();
        });
        this.events.subscribe('user:getAllUsersCount', function () {
            _this.getAllUsersCount();
        });
        this.events.subscribe('user:changeRole', function (role, id) {
            _this.changeRole(role, id);
        });
        this.events.subscribe('user:updateUserDetails', function (data) {
            _this.updateUserDetails(data);
        });
        this.events.subscribe('user:completeOrder', function (uid, oid) {
            _this.completeOrder(uid, oid);
        });
        this.events.subscribe('user:cancelOrder', function (oid) {
            _this.cancelOrder(oid);
        });
        this.events.subscribe('user:setPaytmNo', function (paytmNo) {
            _this.setPaytmNo(paytmNo);
        });
        this.events.subscribe('user:setPhonePeNo', function (phonepeNo) {
            //// console.log('in setPhonePeNo sub..');
            _this.setPhonePeNo(phonepeNo);
        });
        this.events.subscribe('user:setUpiId', function (upiId) {
            _this.setUpiId(upiId);
        });
        this.events.subscribe('user:deletePrdouctFromChatAndOrders', function (orderId, msgId, productId) {
            //// console.log('in deletePrdouctFromChatAndOrders subscribe');
            _this.deletePrdouctFromChatAndOrders(orderId, msgId, productId);
        });
        this.events.subscribe('user:saveNewAddress', function (addressInfo, type) {
            _this.saveNewAddress(addressInfo, type);
        });
        this.events.subscribe('user:editSavedAddress', function (addressInfo, type) {
            _this.editSavedAddress(addressInfo, type);
        });
        this.events.subscribe('user:deleteAddress', function (address) {
            _this.deleteAddress(address);
        });
        this.events.subscribe('user:getAllSavedAddresses', function () {
            _this.getAllSavedAddresses();
        });
        this.events.subscribe('user:getOrderDetailsWithOrderId', function (orderId) {
            _this.getOrderDetailsWithOrderId(orderId);
        });
        this.events.subscribe('user:getAllOrdersOfUser', function (uid) {
            _this.getAllOrdersOfUser(uid);
        });
        this.events.subscribe('user:getPendingOrdersForAdmin', function () {
            _this.getPendingOrdersForAdmin();
        });
        this.events.subscribe('user:loadMorePendingOrdersForAdmin', function () {
            _this.loadMorePendingOrdersForAdmin();
        });
        this.events.subscribe('user:getCompletedOrdersForAdmin', function () {
            _this.getCompletedOrdersForAdmin();
        });
        this.events.subscribe('user:loadMoreCompletedOrdersForAdmin', function () {
            _this.loadMoreCompletedOrdersForAdmin();
        });
        this.events.subscribe('user:getProductsNeedToDeliverForAdmin', function () {
            _this.getProductsNeedToDeliverForAdmin();
        });
        this.events.subscribe('user:loadMoreProductsNeedToDeliverForAdmin', function () {
            _this.loadMoreProductsNeedToDeliverForAdmin();
        });
        this.events.subscribe('user:rejectOrderByAdmin', function (orderId) {
            _this.rejectOrderByAdmin(orderId);
        });
        this.events.subscribe('user:confirmOrderByAdmin', function (orderDetails, orderId) {
            _this.confirmOrderByAdmin(orderDetails, orderId);
        });
        this.events.subscribe('user:cancelOrderByAdmin', function (orderId) {
            _this.cancelOrderByAdmin(orderId);
        });
        this.events.subscribe('user:dispatchOrderByAdmin', function (orderId) {
            _this.dispatchOrderByAdmin(orderId);
        });
        this.events.subscribe('user:deliverOrderByAdmin', function (orderId) {
            _this.deliverOrderByAdmin(orderId);
        });
        this.events.subscribe('user:returnOrderByAdmin', function (orderId) {
            _this.returnOrderByAdmin(orderId);
        });
        this.events.subscribe('user:cancelOrderByUser', function (orderId, cancelReason) {
            _this.cancelOrderByUser(orderId, cancelReason);
        });
        this.events.subscribe('user:setPaymentModeOfOrderByUser', function (paymentMode, orderId) {
            _this.setPaymentModeOfOrderByUser(paymentMode, orderId);
        });
        this.events.subscribe('user:blockUser', function (uid) {
            _this.blockUser(uid);
        });
        this.events.subscribe('user:blockAndDeleteData', function (uid) {
            _this.blockAndDeleteData(uid);
        });
        this.events.subscribe('user:unblockUser', function (uid) {
            _this.unblockUser(uid);
        });
        this.events.subscribe('user:acceptTermsAndConds', function (uid) {
            _this.acceptTermsAndConds(uid);
        });
        this.events.subscribe('user:updateNameOfNewUser', function (data, uid) {
            _this.updateNameOfNewUser(data, uid);
        });
        this.events.subscribe('user:getAllDeliveryAgents', function () {
            _this.getAllDeliveryAgents();
        });
        this.events.subscribe('user:assignDeliveryAgent', function (agentId, orderId) {
            _this.assignDeliveryAgent(agentId, orderId);
        });
        this.events.subscribe('user:getAddressFromLatLng', function (lat, lng) {
            _this.getAddressFromLatLng(lat, lng);
        });
        this.events.subscribe('user:getTotalUsers', function () {
            _this.getTotalUsers();
        });
        this.userRef = this.afs.collection('users');
        this.mediaRef = this.afs.collection('media');
    };
    UserService.prototype.addUserId = function (uid) {
        //// console.log('uid:', uid);
        this.userId = uid;
    };
    UserService.prototype.addPhoneNo = function (phoneNo) {
        //// console.log(phoneNo, typeof phoneNo);
        this.phoneNo = phoneNo;
    };
    UserService.prototype.getUserId = function () {
        //// console.log('serice getUserId', this.userId)
        return this.userId;
    };
    UserService.prototype.getStorageUid = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.storage.get('uid').then(function (val) {
                                resolve(val);
                            });
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    UserService.prototype.getPhoneNo = function () {
        return this.phoneNo;
    };
    UserService.prototype.addUserName = function (userName) {
        this.userName = userName;
    };
    UserService.prototype.getUserName = function () {
        return this.userName;
    };
    UserService.prototype.addUserEmail = function (email) {
        this.userEmail = email;
    };
    UserService.prototype.getUserEmail = function () {
        return this.userEmail || '';
    };
    UserService.prototype.addUser = function (uid, name, email, phoneNo, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                //// console.log('in add user...');
                this.user.name = name;
                this.user.lowercaseName = name.toLowerCase();
                this.user.email = email;
                this.user.phoneNo = phoneNo;
                this.user.createdAt = new Date();
                this.user.lastAccessAt = new Date();
                this.user.active = true;
                this.user.role = 'user';
                this.user.vacations = { active: false, start: null, end: null };
                this.user.dP = 'assets/img/user-pic.gif';
                this.user.readTerms = false;
                this.user.loginMode = type;
                try {
                    this.userRef.doc(uid).set(this.user);
                    this.storage.set('userName', this.user.name);
                    this.addUserName(this.user.name);
                    this.events.publish('user:userCreatedSuccessfully', uid, name, email);
                }
                catch (err) {
                    console.dir(err);
                }
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.checkUserAlreadyExistsOrNot = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var usersRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                usersRef = this.afs.collection('users').doc(uid);
                usersRef.get().subscribe(function (docSnap) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (docSnap.exists) {
                            //// console.log('user exists already...');
                            //// console.log('docSnap data...', docSnap.data());
                            if (docSnap.data().setFromUI) {
                                this.events.publish('user:userAlreadyExists', uid);
                            }
                            else {
                                //// console.log('setFromUI is false');
                                this.user.wallet = 'wallet' in docSnap.data() ? docSnap.data().wallet : this.user.wallet;
                                this.addUser(uid, 'user', '', this.getPhoneNo(), 'otp');
                            }
                        }
                        else {
                            //// console.log('user NOT exists...');
                            this.addUser(uid, 'user', '', this.getPhoneNo(), 'otp');
                        }
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.socialSignInUserCheck = function (uid, name, email, phoneNo, loginType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var usersRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                name = name ? name : 'user';
                phoneNo = phoneNo ? phoneNo : '';
                email = email ? email : '';
                this.storage.set('uid', uid);
                usersRef = this.afs.collection('users').doc(uid);
                usersRef.get().subscribe(function (docSnap) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (docSnap.exists) {
                            //// console.log('user exists already...');
                            //// console.log('docSnap data...', docSnap.data());
                            if (docSnap.data().setFromUI) {
                                this.events.publish('user:userAlreadyExists', uid);
                            }
                            else {
                                //// console.log('setFromUI is false');
                                this.addUser(uid, name, email, phoneNo, loginType);
                            }
                        }
                        else {
                            //// console.log('user NOT exists...');
                            this.addUser(uid, name, email, phoneNo, loginType);
                        }
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.updateNameOfNewUser = function (data, uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.afs.collection('users').doc(uid).update(data)];
                    case 1:
                        _a.sent();
                        this.events.publish('user:updateNameOfNewUserSuccess');
                        return [4 /*yield*/, this.afs.collection('chats').doc(this.getUserId()).update({ name: data.name })];
                    case 2:
                        _a.sent();
                        this.addUserName(data.name);
                        this.addUserEmail(data.email);
                        this.storage.set('userName', data.name);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getUserInfo = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userRef.doc(uid).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        userData = _a.sent();
                        this.events.publish('user:publishUserInfo', userData);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.addUserImage = function (base64Image) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var imgRef, downloadURL, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        imgRef = this.fbStorage.ref("profile/" + this.userId + "/images/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(base64Image, 'data_url')];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                    case 2:
                        downloadURL = _a.sent();
                        //// console.log('user image download url');
                        //// console.log(downloadURL);
                        return [4 /*yield*/, this.afs.collection('users').doc(this.userId).update({ dP: downloadURL })];
                    case 3:
                        //// console.log('user image download url');
                        //// console.log(downloadURL);
                        _a.sent();
                        this.events.publish('user:uploadProdilePicSuccess', downloadURL);
                        this.storage.set('userPic', downloadURL);
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        console.dir(err_1);
                        this.events.publish('user:uploadProdilePicFailure');
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getUserDetails = function (callType, userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, _a, usersData;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!userId) return [3 /*break*/, 1];
                        _a = userId;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getStorageUid()];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        uid = _a;
                        return [4 /*yield*/, this.afs.doc("users/" + uid).valueChanges().pipe(first()).toPromise()];
                    case 4:
                        usersData = _b.sent();
                        if (callType === 'return') {
                            return [2 /*return*/, usersData];
                        }
                        else {
                            this.events.publish('user:publishUserDetails', usersData);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.setActiveVacation = function (vacationDetails) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userRef.doc(this.getUserId()).update({ vacations: vacationDetails })];
                    case 1:
                        _a.sent();
                        if (vacationDetails.active === false) {
                            this.events.publish('user:vacationsSuccess');
                        }
                        else {
                            this.events.publish('user:vacationsSuccess');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.setPaytmNo = function (paytmNo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('config').doc('paytm').set({ paytmNo: paytmNo })];
                    case 1:
                        _a.sent();
                        this.events.publish('user:setPaytmNoSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        console.dir(err_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.setPhonePeNo = function (phonepeNo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('config').doc('phonepe').set({ phonepeNo: phonepeNo })];
                    case 1:
                        _a.sent();
                        this.events.publish('user:setPhonePeNoSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        console.dir(err_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.setUpiId = function (upiId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('config').doc('upi').set({ upiId: upiId })];
                    case 1:
                        _a.sent();
                        this.events.publish('user:setUpiIdSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        err_4 = _a.sent();
                        console.dir(err_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getVacationsDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userRef.doc(this.getUserId()).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        userData = _a.sent();
                        this.events.publish('user:publishVacationsDetails', userData.vacations, userData.name);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getAllUsers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allUsersRef, allUsers;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    allUsersRef = this.afs.collection('users');
                    allUsers = allUsersRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                        var data = a.payload.doc.data();
                        var id = a.payload.doc.id;
                        return tslib_1.__assign({ id: id }, data);
                    }); }));
                    allUsers.subscribe(function (res) {
                        if (!res.length) {
                            _this.events.publish('user:noUsers');
                        }
                        else {
                            _this.events.publish('user:publishAllUsersData', res);
                        }
                    });
                }
                catch (err) {
                    console.dir(err);
                }
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.getUsersForAdminUsers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.usersDataForAdminProducts = [];
                    this.afs.collection('users', function (ref) { return ref
                        .limit(200).orderBy('lastAccessAt', 'desc'); }).snapshotChanges()
                        .subscribe(function (response) {
                        if (!response.length) {
                            //// console.log('No Data Available');
                            _this.events.publish('user:noUsers');
                            return false;
                        }
                        _this.usersDataForAdminProducts = [];
                        _this.lastResponseForUserProducts = response[response.length - 1].payload.doc;
                        for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
                            var user = response_1[_i];
                            _this.usersDataForAdminProducts.push({ id: user.payload.doc.id, data: user.payload.doc.data() });
                        }
                        //// console.log('users in getUsersForAdminUsers', this.usersDataForAdminProducts);
                        _this.events.publish('user:publishUsersForAdminUsers', _this.usersDataForAdminProducts);
                    }, function (error) {
                    });
                }
                catch (err) {
                    console.dir(err);
                }
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.loadMoreUsersForAdminUsers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    //// console.log('in loadMoreProducts service...', this.lastResponseForUserProducts.id);
                    this.afs.collection('users', function (ref) { return ref
                        .limit(200)
                        .startAfter(_this.lastResponseForUserProducts); }).snapshotChanges()
                        .subscribe(function (response) {
                        if (!response.length) {
                            //// console.log('No Data Available');
                            _this.events.publish('user:usersForAdminProductsLimitReached');
                            return false;
                        }
                        _this.lastResponseForUserProducts = response[response.length - 1].payload.doc;
                        //// console.log('response in loadmore', response);
                        for (var _i = 0, response_2 = response; _i < response_2.length; _i++) {
                            var user = response_2[_i];
                            _this.usersDataForAdminProducts.push({ id: user.payload.doc.id, data: user.payload.doc.data() });
                        }
                        //// console.log('load more users in loadMorepublishUsersForAdminUsers', this.usersDataForAdminProducts);
                        _this.events.publish('user:publishUsersForAdminUsers', _this.usersDataForAdminProducts);
                    }, function (error) {
                    });
                }
                catch (err) {
                    console.dir(err);
                }
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.getAllUsersCount = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var users, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('users').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        users = _a.sent();
                        this.events.publish('user:publishAllUsersCount', users.length);
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        console.dir(err_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.changeRole = function (role, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(role === 'delivery agent')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.afs.doc("users/" + id).update({ role: 'deliveryAgent' })];
                    case 1:
                        _a.sent();
                        this.events.publish('user:changeRoleSuccess', role);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.afs.doc("users/" + id).update({ role: role })];
                    case 3:
                        _a.sent();
                        this.events.publish('user:changeRoleSuccess', role);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_6 = _a.sent();
                        console.dir(err_6);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.updateUserDetails = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.userRef.doc(this.getUserId()).update(data)];
                    case 1:
                        _a.sent();
                        if (!(data.role === 'user')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.afs.collection('chats').doc(this.getUserId()).update(data)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.events.publish('user:updateUserDetailsSuccess');
                        this.storage.set('userName', data.name);
                        this.addUserName(data.name);
                        this.addUserEmail(data.email);
                        return [3 /*break*/, 5];
                    case 4:
                        err_7 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getOrdersWithId = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ordersRef, ordersData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                ordersRef = this.afs.collection('users').doc(uid).collection('orders');
                ordersData = ordersRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                    var data = a.payload.doc.data();
                    var id = a.payload.doc.id;
                    return tslib_1.__assign({ id: id }, data);
                }); })).pipe(first());
                ordersData.subscribe(function (orders) {
                    //// console.log('orders:::', orders);
                    if (!orders.length) {
                        return false;
                    }
                    else {
                        _this.orders = orders;
                    }
                });
                return [2 /*return*/, this.orders];
            });
        });
    };
    UserService.prototype.completeOrder = function (uid, oid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var msgRef, msg, err_8;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.doc("users/" + uid).collection('orders').doc(oid).update({ status: 'Completed' })];
                    case 1:
                        _a.sent();
                        msgRef = this.afs.collection('chats').doc(uid).collection('messages', function (ref) { return ref.where('orderId', '==', oid); });
                        msg = msgRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                            var data = a.payload.doc.data();
                            var id = a.payload.doc.id;
                            return tslib_1.__assign({ id: id }, data);
                        }); })).pipe(first());
                        msg.subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: 
                                    //// console.log('my msg', res);
                                    return [4 /*yield*/, this.afs.collection('chats').doc(uid).collection('messages').doc(res[0].id).update({ status: 'Completed' })];
                                    case 1:
                                        //// console.log('my msg', res);
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        this.events.publish('user:completeOrderSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        err_8 = _a.sent();
                        console.dir(err_8);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.cancelOrder = function (oid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var msgRef, msg, err_9;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.userRef.doc(this.getUserId()).collection('orders').doc(oid).update({ status: 'Cancelled' })];
                    case 1:
                        _a.sent();
                        msgRef = this.afs.collection('chats').doc(this.getUserId()).collection('messages', function (ref) { return ref.where('orderId', '==', oid); });
                        msg = msgRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                            var data = a.payload.doc.data();
                            var id = a.payload.doc.id;
                            return tslib_1.__assign({ id: id }, data);
                        }); })).pipe(first());
                        msg.subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: 
                                    //// console.log('my msg', res);
                                    return [4 /*yield*/, this.afs.collection('chats').doc(this.getUserId()).collection('messages').doc(res[0].id).update({ status: 'Cancelled' })];
                                    case 1:
                                        //// console.log('my msg', res);
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        this.events.publish('user:cancelOrderSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        err_9 = _a.sent();
                        console.dir(err_9);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.deletePrdouctFromChatAndOrders = function (orderId, msgId, productId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderData, msgData, i;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection('users').doc(this.getUserId()).collection('orders').doc(orderId).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('chats').doc(this.getUserId()).collection('messages').doc(msgId).valueChanges().pipe(first()).toPromise()];
                    case 2:
                        msgData = _a.sent();
                        //// console.log('order Data for delete', orderData);
                        //// console.log('msg Data for delete', msgData);
                        for (i = 0; i < orderData.products.length; i++) {
                            if (orderData.products[i].productId === productId) {
                                orderData.products.splice(i, 1);
                            }
                            if (msgData.products[i].productId === productId) {
                                msgData.products.splice(i, 1);
                            }
                        }
                        if (!(orderData.products.length === 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.afs.collection('users').doc(this.getUserId()).collection('orders').doc(orderId).delete()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.afs.collection('users').doc(this.getUserId()).collection('orders').doc(orderId).update({ products: orderData.products })];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        if (!(msgData.products.length === 0)) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.afs.collection('chats').doc(this.getUserId()).collection('messages').doc(msgId).delete()];
                    case 7:
                        _a.sent();
                        return [3 /*break*/, 10];
                    case 8: return [4 /*yield*/, this.afs.collection('chats').doc(this.getUserId()).collection('messages').doc(msgId).update({ products: msgData.products })];
                    case 9:
                        _a.sent();
                        _a.label = 10;
                    case 10:
                        this.events.publish('user:deleteProductSuccesss');
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.saveNewAddress = function (addressInfo, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userId, _a, addressRef, allAddressData, index, err_10;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 13, , 14]);
                        if (!this.getUserId()) return [3 /*break*/, 1];
                        _a = this.getUserId();
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.storage.get('uid')];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        userId = _a;
                        return [4 /*yield*/, this.afs.collection('users').doc(userId).collection('addresses').add(addressInfo)];
                    case 4:
                        addressRef = _b.sent();
                        if (!(addressInfo.defaultAddress === true && type !== 'billing')) return [3 /*break*/, 10];
                        return [4 /*yield*/, this.afs.collection('users').doc(userId).update({ defaultAddress: addressInfo })];
                    case 5:
                        _b.sent();
                        this.storage.set('userDefaultAddress', addressInfo);
                        return [4 /*yield*/, this.afs.collection('users').doc(userId).collection('addresses').snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 6:
                        allAddressData = _b.sent();
                        index = 0;
                        _b.label = 7;
                    case 7:
                        if (!(index < allAddressData.length)) return [3 /*break*/, 10];
                        if (!(allAddressData[index].id !== addressRef.id)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.afs.collection('users').doc(userId).collection('addresses').doc(allAddressData[index].id).update({ defaultAddress: false })];
                    case 8:
                        _b.sent();
                        _b.label = 9;
                    case 9:
                        index++;
                        return [3 /*break*/, 7];
                    case 10:
                        if (!(type === 'billing')) return [3 /*break*/, 12];
                        return [4 /*yield*/, this.storage.set('userBillingAddress', addressInfo)];
                    case 11:
                        _b.sent();
                        _b.label = 12;
                    case 12:
                        this.events.publish('user:newAddressSaved');
                        return [3 /*break*/, 14];
                    case 13:
                        err_10 = _b.sent();
                        console.dir(err_10);
                        return [3 /*break*/, 14];
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.editSavedAddress = function (addressInfo, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allAddressData, index, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.afs.collection('users').doc(this.getUserId()).collection('addresses').doc(addressInfo.id).update(addressInfo)];
                    case 1:
                        _a.sent();
                        if (!(addressInfo.defaultAddress === true && type !== 'billing')) return [3 /*break*/, 3];
                        this.afs.collection('users').doc(this.getUserId()).update({ defaultAddress: addressInfo });
                        this.storage.set('userDefaultAddress', addressInfo);
                        return [4 /*yield*/, this.afs.collection('users').doc(this.getUserId()).collection('addresses').snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 2:
                        allAddressData = _a.sent();
                        for (index = 0; index < allAddressData.length; index++) {
                            if (allAddressData[index].id !== addressInfo.id) {
                                this.afs.collection('users').doc(this.getUserId()).collection('addresses').doc(allAddressData[index].id).update({ defaultAddress: false });
                            }
                        }
                        _a.label = 3;
                    case 3:
                        if (type === 'billing') {
                            this.storage.set('userBillingAddress', addressInfo);
                        }
                        this.events.publish('user:addressEditSuccess');
                        return [3 /*break*/, 5];
                    case 4:
                        err_11 = _a.sent();
                        console.dir(err_11);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.deleteAddress = function (address) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allAddressData, addressInfo, err_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.afs.collection('users').doc(this.getUserId()).collection('addresses').doc(address.id).delete()];
                    case 1:
                        _a.sent();
                        if (!(address.defaultAddress === true)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.afs.collection('users').doc(this.getUserId()).collection('addresses', function (ref) { return ref.orderBy('createdAt', 'desc'); }).snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 2:
                        allAddressData = _a.sent();
                        if (allAddressData.length) {
                            addressInfo = {
                                name: allAddressData[0].name,
                                address: allAddressData[0].address,
                                city: allAddressData[0].city,
                                state: allAddressData[0].state,
                                pincode: allAddressData[0].pincode,
                                phoneNo: allAddressData[0].phoneNo,
                                defaultAddress: true
                            };
                            this.afs.collection('users').doc(this.getUserId()).update({ defaultAddress: addressInfo });
                            this.storage.set('userDefaultAddress', addressInfo);
                            this.afs.collection('users').doc(this.getUserId()).collection('addresses').doc(allAddressData[0].id).update({ defaultAddress: true });
                        }
                        else {
                            this.storage.set('userDefaultAddress', null);
                            this.afs.collection('users').doc(this.getUserId()).update({ defaultAddress: null });
                        }
                        _a.label = 3;
                    case 3:
                        this.events.publish('user:deleteAddressSuccess');
                        return [3 /*break*/, 5];
                    case 4:
                        err_12 = _a.sent();
                        console.dir(err_12);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getAllSavedAddresses = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userId, _a, addressRef, addressSnap, err_13;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        if (!this.getUserId()) return [3 /*break*/, 1];
                        _a = this.getUserId();
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.storage.get('uid')];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        userId = _a;
                        addressRef = this.afs.collection('users').doc(userId).collection('addresses', function (ref) { return ref.orderBy('createdAt', 'desc'); });
                        addressSnap = addressRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                            var data = a.payload.doc.data();
                            var id = a.payload.doc.id;
                            return tslib_1.__assign({ id: id }, data);
                        }); }));
                        addressSnap.subscribe(function (result) {
                            _this.events.publish('user:publishAllSavedAddresses', result);
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        err_13 = _b.sent();
                        console.dir(err_13);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getOrderDetailsWithOrderId = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid_1, orderData, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.getStorageUid()];
                    case 1:
                        uid_1 = _a.sent();
                        return [4 /*yield*/, this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId)
                                .where('userId', '==', uid_1); }).snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 2:
                        orderData = _a.sent();
                        this.events.publish('user:publishOrderDetailsWithOrderId', orderData);
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getAllOrdersOfUser = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userId_1, ordersRef, ordersSnap, error_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        userId_1 = '';
                        if (!uid) return [3 /*break*/, 1];
                        userId_1 = uid;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getStorageUid()];
                    case 2:
                        userId_1 = _a.sent();
                        _a.label = 3;
                    case 3:
                        ordersRef = this.afs.collection('orders', function (ref) { return ref
                            .where('userId', '==', userId_1)
                            .orderBy('createdAt', 'desc'); });
                        ordersSnap = ordersRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                            var data = a.payload.doc.data();
                            var id = a.payload.doc.id;
                            return tslib_1.__assign({ id: id }, data);
                        }); }));
                        ordersSnap.subscribe(function (orders) {
                            if (!orders.length) {
                                _this.events.publish('user:noOrderHistoryOfUser');
                            }
                            else {
                                _this.events.publish('user:publishAllOrdersOfUser', orders);
                            }
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getPendingOrdersForAdmin = function () {
        var _this = this;
        try {
            var ordersRef = this.afs.collection('orders', function (ref) { return ref
                .orderBy('createdAt', 'desc')
                .where('status', 'in', ['Pending', 'Confirmed', 'Dispatched'])
                .limit(environment.scrollLimit); });
            var ordersSnap = ordersRef.snapshotChanges();
            ordersSnap.subscribe(function (orders) {
                _this.pendingOrdersForAdmin = [];
                if (!orders.length) {
                    _this.events.publish('user:noPendingOrdersForAdmin');
                }
                else {
                    _this.lastResponseOfPendingOrdersForAdmin = orders[orders.length - 1].payload.doc;
                    for (var _i = 0, orders_1 = orders; _i < orders_1.length; _i++) {
                        var order = orders_1[_i];
                        _this.pendingOrdersForAdmin.push(tslib_1.__assign({ id: order.payload.doc.id }, order.payload.doc.data()));
                        _this.events.publish('user:publishPendingOrdersForAdmin', _this.pendingOrdersForAdmin);
                    }
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    UserService.prototype.loadMorePendingOrdersForAdmin = function () {
        var _this = this;
        try {
            var ordersRef = this.afs.collection('orders', function (ref) { return ref
                .orderBy('createdAt', 'desc')
                .where('status', 'in', ['Pending', 'Confirmed', 'Dispatched'])
                .limit(environment.scrollLimit)
                .startAfter(_this.lastResponseOfPendingOrdersForAdmin); });
            var ordersSnap = ordersRef.snapshotChanges();
            ordersSnap.subscribe(function (orders) {
                if (!orders.length) {
                    _this.events.publish('user:noMorePendingOrdersForAdmin');
                    return false;
                }
                _this.lastResponseOfPendingOrdersForAdmin = orders[orders.length - 1].payload.doc;
                for (var _i = 0, orders_2 = orders; _i < orders_2.length; _i++) {
                    var order = orders_2[_i];
                    _this.pendingOrdersForAdmin.push(tslib_1.__assign({ id: order.payload.doc.id }, order.payload.doc.data()));
                    _this.events.publish('user:publishPendingOrdersForAdmin', _this.pendingOrdersForAdmin);
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    UserService.prototype.getCompletedOrdersForAdmin = function () {
        var _this = this;
        try {
            var ordersRef = this.afs.collection('orders', function (ref) { return ref
                .orderBy('createdAt', 'desc')
                .where('status', 'in', ['Rejected', 'Cancelled', 'Delivered', 'Returned'])
                .limit(environment.scrollLimit); });
            var ordersSnap = ordersRef.snapshotChanges();
            ordersSnap.subscribe(function (orders) {
                _this.completedOrdersForAdmin = [];
                if (!orders.length) {
                    _this.events.publish('user:noCompletedOrdersForAdmin');
                }
                else {
                    _this.lastResponseOfCompletedOrdersForAdmin = orders[orders.length - 1].payload.doc;
                    for (var _i = 0, orders_3 = orders; _i < orders_3.length; _i++) {
                        var order = orders_3[_i];
                        _this.completedOrdersForAdmin.push(tslib_1.__assign({ id: order.payload.doc.id }, order.payload.doc.data()));
                        _this.events.publish('user:publishCompletedOrdersForAdmin', _this.completedOrdersForAdmin);
                    }
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    UserService.prototype.loadMoreCompletedOrdersForAdmin = function () {
        var _this = this;
        try {
            var ordersRef = this.afs.collection('orders', function (ref) { return ref
                .orderBy('createdAt', 'desc')
                .limit(environment.scrollLimit)
                .startAfter(_this.lastResponseOfCompletedOrdersForAdmin); });
            var ordersSnap = ordersRef.snapshotChanges();
            ordersSnap.subscribe(function (orders) {
                if (!orders.length) {
                    _this.events.publish('user:noMoreCompletedOrdersForAdmin');
                    return false;
                }
                _this.lastResponseOfCompletedOrdersForAdmin = orders[orders.length - 1].payload.doc;
                for (var _i = 0, orders_4 = orders; _i < orders_4.length; _i++) {
                    var order = orders_4[_i];
                    _this.completedOrdersForAdmin.push(tslib_1.__assign({ id: order.payload.doc.id }, order.payload.doc.data()));
                    _this.events.publish('user:publishCompletedOrdersForAdmin', _this.completedOrdersForAdmin);
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    UserService.prototype.getProductsNeedToDeliverForAdmin = function () {
        var _this = this;
        try {
            this.productsNeedToDeliverForAdmin = [];
            var ordersRef = this.afs.collection('orders', function (ref) { return ref
                .orderBy('createdAt', 'desc')
                .where('status', 'in', ['Pending', 'Confirmed'])
                .limit(environment.scrollLimit); });
            var ordersSnap = ordersRef.snapshotChanges();
            ordersSnap.subscribe(function (orders) {
                if (!orders.length) {
                    _this.events.publish('user:noProductsNeedToDeliverForAdmin');
                }
                else {
                    _this.lastResponseOfProductsNeedToDeliverForAdmin = orders[orders.length - 1].payload.doc;
                    for (var _i = 0, orders_5 = orders; _i < orders_5.length; _i++) {
                        var order = orders_5[_i];
                        _this.productsNeedToDeliverForAdmin.push(tslib_1.__assign({ id: order.payload.doc.id }, order.payload.doc.data()));
                        _this.events.publish('user:publishProductsNeedToDeliverForAdmin', _this.productsNeedToDeliverForAdmin);
                    }
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    UserService.prototype.loadMoreProductsNeedToDeliverForAdmin = function () {
        var _this = this;
        try {
            var ordersRef = this.afs.collection('orders', function (ref) { return ref
                .orderBy('createdAt', 'desc')
                .where('status', 'in', ['Pending', 'Confirmed'])
                .limit(environment.scrollLimit)
                .startAfter(_this.lastResponseOfProductsNeedToDeliverForAdmin); });
            var ordersSnap = ordersRef.snapshotChanges();
            ordersSnap.subscribe(function (orders) {
                if (!orders.length) {
                    _this.events.publish('user:noMoreProductsNeedToDeliverForAdmin');
                    return false;
                }
                _this.lastResponseOfProductsNeedToDeliverForAdmin = orders[orders.length - 1].payload.doc;
                for (var _i = 0, orders_6 = orders; _i < orders_6.length; _i++) {
                    var order = orders_6[_i];
                    _this.productsNeedToDeliverForAdmin.push(tslib_1.__assign({ id: order.payload.doc.id }, order.payload.doc.data()));
                    _this.events.publish('user:publishProductsNeedToDeliverForAdmin', _this.productsNeedToDeliverForAdmin);
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    UserService.prototype.rejectOrderByAdmin = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, rejectChatMsg, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({ status: 'Rejected' })];
                    case 2:
                        _a.sent();
                        rejectChatMsg = {
                            author: 'admin',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: orderData[0].orderId,
                            published: true,
                            status: 'Rejected',
                            type: 'order'
                        };
                        this.events.publish('chat:sendMsg', rejectChatMsg, orderData[0].userId);
                        this.events.publish('user:rejectedOrderSuccessfully');
                        return [3 /*break*/, 4];
                    case 3:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.confirmOrderByAdmin = function (orderDetails, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, confrimChatMsg, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({
                                status: 'Confirmed',
                                products: orderDetails.products,
                                productsPrice: orderDetails.productsPrice,
                                totalAmountToPaid: orderDetails.totalAmountToPaid
                            })];
                    case 2:
                        _a.sent();
                        confrimChatMsg = {
                            author: 'admin',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: orderData[0].orderId,
                            published: true,
                            status: 'Confirmed',
                            type: 'order'
                        };
                        this.events.publish('chat:sendMsg', confrimChatMsg, orderData[0].userId);
                        this.events.publish('user:confirmedOrderSuccessfully');
                        return [3 /*break*/, 4];
                    case 3:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.cancelOrderByAdmin = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, cancelChatMsg, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({ status: 'Cancelled' })];
                    case 2:
                        _a.sent();
                        cancelChatMsg = {
                            author: 'admin',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: orderData[0].orderId,
                            published: true,
                            status: 'Cancelled',
                            type: 'order'
                        };
                        this.events.publish('chat:sendMsg', cancelChatMsg, orderData[0].userId);
                        this.events.publish('user:cancelledOrderSuccessfully');
                        return [3 /*break*/, 4];
                    case 3:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.dispatchOrderByAdmin = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, dispatchChatMsg, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({ status: 'Dispatched' })];
                    case 2:
                        _a.sent();
                        dispatchChatMsg = {
                            author: 'admin',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: orderData[0].orderId,
                            published: true,
                            status: 'Dispatched',
                            type: 'order'
                        };
                        this.events.publish('chat:sendMsg', dispatchChatMsg, orderData[0].userId);
                        this.events.publish('user:dispatchedOrderSuccessfully');
                        return [3 /*break*/, 4];
                    case 3:
                        error_7 = _a.sent();
                        console.dir(error_7);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.deliverOrderByAdmin = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, updateObj, deliverChatMsg, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        updateObj = {
                            status: 'Delivered'
                        };
                        if (orderData[0].payment.mode === 'cash' && !orderData[0].payment.completed) {
                            updateObj['payment.completed'] = true;
                        }
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update(updateObj)];
                    case 2:
                        _a.sent();
                        deliverChatMsg = {
                            author: 'admin',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: orderData[0].orderId,
                            published: true,
                            status: 'Delivered',
                            type: 'order'
                        };
                        this.events.publish('chat:sendMsg', deliverChatMsg, orderData[0].userId);
                        this.events.publish('user:deliveredOrderSuccessfully');
                        return [3 /*break*/, 4];
                    case 3:
                        error_8 = _a.sent();
                        console.dir(error_8);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.returnOrderByAdmin = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, returnChatMsg, error_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({ status: 'Returned' })];
                    case 2:
                        _a.sent();
                        returnChatMsg = {
                            author: 'admin',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: orderData[0].orderId,
                            published: true,
                            status: 'Returned',
                            type: 'order'
                        };
                        this.events.publish('chat:sendMsg', returnChatMsg, orderData[0].userId);
                        this.events.publish('user:returnedOrderSuccessfully');
                        return [3 /*break*/, 4];
                    case 3:
                        error_9 = _a.sent();
                        console.dir(error_9);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.cancelOrderByUser = function (orderId, cancelReason, callType) {
        if (callType === void 0) { callType = 'event'; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, updateObj, cancelChatMsg, error_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        updateObj = {
                            cancelData: {
                                reason: cancelReason,
                                by: this.getUserName() + " (User)"
                            },
                            status: 'Cancelled'
                        };
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update(updateObj)];
                    case 2:
                        _a.sent();
                        cancelChatMsg = {
                            author: 'user',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: orderData[0].orderId,
                            published: true,
                            status: 'Cancelled',
                            type: 'order'
                        };
                        this.events.publish('chat:sendMsg', cancelChatMsg, orderData[0].userId);
                        if (callType === 'return') {
                            return [2 /*return*/, true];
                        }
                        else {
                            this.events.publish('user:cancelledOrderByUserSuccessfully');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_10 = _a.sent();
                        console.dir(error_10);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.setPaymentModeOfOrderByUser = function (paymentMode, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, paymentChatMsg, error_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({ payment: { mode: paymentMode } })];
                    case 2:
                        _a.sent();
                        paymentChatMsg = {
                            author: 'user',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: orderData[0].orderId,
                            published: true,
                            status: 'PaymentMsg',
                            type: 'order',
                            paymentMode: paymentMode
                        };
                        this.events.publish('chat:sendMsg', paymentChatMsg, orderData[0].userId);
                        this.events.publish('user:setPaymentModeOfOrderByUserSuccessfully');
                        return [3 /*break*/, 4];
                    case 3:
                        error_11 = _a.sent();
                        console.dir(error_11);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.blockUser = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.userRef.doc(uid).update({ active: false })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.afs.collection('block').doc(uid).set({ deleteData: false })];
                    case 2:
                        _a.sent();
                        this.events.publish('user:userBlockedSuccessfully');
                        return [3 /*break*/, 4];
                    case 3:
                        error_12 = _a.sent();
                        console.dir(error_12.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.unblockUser = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.userRef.doc(uid).update({ active: true })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.afs.collection('block').doc(uid).delete()];
                    case 2:
                        _a.sent();
                        this.events.publish('user:userUnblockedSuccessfully');
                        return [3 /*break*/, 4];
                    case 3:
                        error_13 = _a.sent();
                        console.dir(error_13.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.blockAndDeleteData = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('block').doc(uid).set({ deleteData: true })];
                    case 1:
                        _a.sent();
                        this.events.publish('user:userBlockedAndDeleteDataSuccessfully');
                        return [3 /*break*/, 3];
                    case 2:
                        error_14 = _a.sent();
                        console.dir(error_14.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.acceptTermsAndConds = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_15;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('users').doc(uid).update({ readTerms: true })];
                    case 1:
                        _a.sent();
                        this.events.publish('user:termsAndCondsAcceptedSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_15 = _a.sent();
                        console.dir(error_15.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getAllDeliveryAgents = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allDeliveryAgentsRef, allDeliveryAgents;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    allDeliveryAgentsRef = this.afs.collection('users', function (ref) { return ref.where('role', '==', 'deliveryAgent'); });
                    allDeliveryAgents = allDeliveryAgentsRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                        var data = a.payload.doc.data();
                        var id = a.payload.doc.id;
                        return tslib_1.__assign({ id: id }, data);
                    }); }));
                    allDeliveryAgents.subscribe(function (res) {
                        if (!res.length) {
                            _this.events.publish('user:noDeliveryAgents');
                        }
                        else {
                            _this.events.publish('user:publishAllDeliveryAgents', res);
                        }
                    });
                }
                catch (err) {
                    console.dir(err);
                }
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.assignDeliveryAgent = function (agentId, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, error_16;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({ deliveryAgentId: agentId, deliveryStatus: 'notStarted' })];
                    case 2:
                        _a.sent();
                        this.events.publish('user:assignDeliveryAgentSuccess');
                        return [3 /*break*/, 4];
                    case 3:
                        error_16 = _a.sent();
                        console.dir(error_16);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getAddressFromLatLng = function (lat, lng) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var getAddressFromLatLng;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    getAddressFromLatLng = firebase.functions().httpsCallable('location-getAddressFromLatLng');
                    getAddressFromLatLng({ lat: lat, lng: lng }).then(function (response) {
                        //// console.log('response', response.data);
                        if (response.data.status !== "OK" || response.data.success === false) {
                            _this.events.publish('user:errorInGettingAddress');
                        }
                        else {
                            _this.events.publish('user:addressValueFromLatLng', response.data);
                        }
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.getTotalUsers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.afs.collection('analytics').doc('users').valueChanges().subscribe(function (data) {
                        _this.events.publish('user:publishTotalUsers', data.count);
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    UserService.prototype.getOrderDetails = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderData, error_17;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [2 /*return*/, orderData];
                    case 2:
                        error_17 = _a.sent();
                        console.dir(error_17);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('user:getUserInfo');
        this.events.unsubscribe('user:addUserImage');
        this.events.unsubscribe('user:getTotalActiveUsers');
        this.events.unsubscribe('user:getUserDetails');
        this.events.unsubscribe('user:setActiveVacation');
        this.events.unsubscribe('user:getVacationsDetails');
        this.events.unsubscribe('user:getAllUsers');
        this.events.unsubscribe('user:getUsersForAdminUsers');
        this.events.unsubscribe('user:loadMoreUsersForAdminUsers');
        this.events.unsubscribe('user:changeRole');
        this.events.unsubscribe('user:updateUserDetails');
        this.events.unsubscribe('user:completeOrder');
        this.events.unsubscribe('user:cancelOrder');
        this.events.unsubscribe('user:setPaytmNo');
        this.events.unsubscribe('user:setPhonePeNo');
        this.events.unsubscribe('user:setUpiId');
        this.events.unsubscribe('user:getPaytmNo');
        this.events.unsubscribe('user:getPhonePeNo');
        this.events.unsubscribe('user:getUpiId');
        this.events.unsubscribe('user:saveNewAddress');
        this.events.unsubscribe('user:editSavedAddress');
        this.events.unsubscribe('user:deleteAddress');
        this.events.unsubscribe('user:getAllSavedAddresses');
        this.events.unsubscribe('user:getOrderDetailsWithOrderId');
        this.events.unsubscribe('user:getAllOrdersOfUser');
        this.events.unsubscribe('user:getAllOrdersForAdmin');
        this.events.unsubscribe('user:rejectOrderByAdmin');
        this.events.unsubscribe('user:confirmOrderByAdmin');
        this.events.unsubscribe('user:cancelOrderByAdmin');
        this.events.unsubscribe('user:dispatchOrderByAdmin');
        this.events.unsubscribe('user:deliverOrderByAdmin');
        this.events.unsubscribe('user:returnOrderByAdmin');
        this.events.unsubscribe('user:cancelOrderByUser');
        this.events.unsubscribe('user:setPaymentModeOfOrderByUser');
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.AngularFireAuth), i0.ɵɵinject(i3.Events), i0.ɵɵinject(i4.AngularFireStorage), i0.ɵɵinject(i5.Storage), i0.ɵɵinject(i6.Router), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i3.NavController), i0.ɵɵinject(i3.ModalController)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
